import styled, { keyframes } from 'styled-components';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHotelStore } from '@/store/hotel';
import { Box } from '@/components';

const DURATION = 6000;

export const LoadingProgress = () => {
  const { progress: realProgress } = useHotelStore();
  const progressRef = useRef<HTMLDivElement>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation('hot-tours');
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % items.en.length);
    }, DURATION);

    let progress = 0;
    const maxLimit = 99.5;
    const duration = Math.random() * 30 + 90;
    const startTime = Date.now();

    const updateProgress = () => {
      if (!progressRef.current) return;

      if (!realProgress || realProgress < progress) {
        const elapsedTime = (Date.now() - startTime) / 1000;
        const timeFraction = elapsedTime / duration;

        progress = maxLimit * (1 - Math.pow(1 - timeFraction, 3));

        progress += Math.random() * 0.05;

        if (progress > maxLimit) {
          progress = maxLimit;
        }

        if (elapsedTime < duration) {
          requestAnimationFrame(updateProgress);
        }
      } else {
        progress = realProgress;
      }

      progressRef.current.style.width = progress + '%';
    };

    updateProgress();

    return () => clearInterval(interval);
  }, [realProgress, progressRef]);

  return (
    <>
      <Box
        $sx={{
          justifySelf: 'start',
          background: 'var(--Stroke-color, #EBEDFA)',
          color: '#000',
          position: 'relative',
          display: 'grid',
          width: '100%',
        }}
      >
        <ProgressBar ref={progressRef} />
        {items[language as keyof typeof items].map(({ title, text }, i) => (
          <Box
            $sx={{
              padding: '12px 16px',
              display: 'grid',
              alignContent: 'start',
              gap: '2px',
              width: '100%',
              'grid-row': '1',
              'grid-column': '1',
              opacity: i === current ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              zIndex: 1,
            }}
            key={i}
          >
            <Box
              $sx={{
                'font-size': '14px',
                'font-weight': '500',
                'line-height': '20px',
              }}
            >
              {title}
            </Box>

            <Box
              $sx={{
                'font-size': '12px',
                'font-weight': '400',
                'line-height': '16px',
              }}
            >
              {text}
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        $gap="8px"
        $align="center"
        $m="var(--xs) 0 0 var(--base)"
        $sx={{
          color: '#000',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px',
        }}
      >
        <StyledSvg fill="none" viewBox="0 0 22 10">
          <path
            fill="#FFA500"
            d="M1.8.8a1 1 0 0 1 1-.8h6a1 1 0 0 1 1 1.2l-1.6 8a1 1 0 0 1-1 .8h-6a1 1 0 0 1-1-1.2l1.6-8Z"
          />
          <path
            fill="#1A9EDB"
            d="M13.8.8a1 1 0 0 1 1-.8h6a1 1 0 0 1 1 1.2l-1.6 8a1 1 0 0 1-1 .8h-6a1 1 0 0 1-1-1.2l1.6-8Z"
          />
        </StyledSvg>

        {t('loading.title')}
      </Box>
    </>
  );
};

const kf = keyframes`
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  transition: width 0.3s ease-in-out;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(26, 158, 219, 0.5);
  width: 0;
`;

const StyledSvg = styled.svg`
  height: 10px;
  flex-shrink: 0;

  & path {
    animation-name: ${kf};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  & path:nth-child(2) {
    animation-delay: 0.2s;
  }
`;

export const items = {
  ru: [
    {
      title: 'Загружаем отели для вас...',
      text: 'Мы ищем лучшие предложения, пожалуйста, подождите.',
    },
    {
      title: 'Поиск отелей...',
      text: 'Находим самые выгодные варианты по вашим запросам.',
    },
    {
      title: 'Подбираем отели...',
      text: 'Мы стараемся подобрать лучшие варианты для вашего отдыха.',
    },
    {
      title: 'Загрузка предложений...',
      text: 'Пожалуйста, немного подождите, мы ищем подходящие отели.',
    },
    {
      title: 'Поиск лучших отелей...',
      text: 'Мы сортируем предложения, чтобы найти для вас лучшие варианты.',
    },
    {
      title: 'Собираем данные...',
      text: 'Осталось совсем немного — скоро появятся лучшие предложения.',
    },
    {
      title: 'Проверяем наличие номеров...',
      text: 'Мы ищем доступные номера, которые подойдут именно вам.',
    },
    {
      title: 'Готовим предложения для вас...',
      text: 'Пожалуйста, немного подождите, мы почти закончили.',
    },
  ],
  en: [
    {
      title: 'Loading hotels for you...',
      text: 'We are finding the best deals, please wait.',
    },
    {
      title: 'Searching for hotels...',
      text: 'Finding the most profitable options based on your request.',
    },
    {
      title: 'Picking the best hotels...',
      text: 'We are working to find the best options for your stay.',
    },
    {
      title: 'Loading available offers...',
      text: 'Please wait a moment while we search for suitable hotels.',
    },
    {
      title: 'Searching for the best hotels...',
      text: 'Sorting through the options to find the best deals for you.',
    },
    {
      title: 'Gathering data...',
      text: 'Almost done — the best offers will be available shortly.',
    },
    {
      title: 'Checking room availability...',
      text: 'We are finding available rooms that suit your preferences.',
    },
    {
      title: 'Preparing offers for you...',
      text: 'Please wait a bit, we are almost done.',
    },
  ],
};
