import { initializeFaro } from '@grafana/faro-react';
import { APIProvider } from '@vis.gl/react-google-maps';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import updateLocale from 'dayjs/plugin/updateLocale';
import { lazy, Suspense } from 'react';

import {
  HistoryRouterProvider,
  QueryProvider,
  ThemeProvider,
} from './providers';
import { useLang } from './util';

import { Loading } from '@/components';
import ErrorBoundary from '@/components/error-boundary';
import { GOOGLE_API_KEY } from '@/constants';
import { history } from '@/utils/history';

if (import.meta.env.PROD) {
  const userId = JSON.parse(
    window.localStorage.getItem('user') ?? '{}',
  )?.userId;
  const deviceId = window.localStorage.getItem('device_id');

  initializeFaro({
    url: 'https://alloy.coreteam.uz/collect',

    app: {
      name: 'app-front',
    },
    user: {
      id: userId?.toString(),
      attributes: deviceId
        ? {
            deviceId,
          }
        : undefined,
    },
  });
}

dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const Router = lazy(() => import('@/router'));

const libraries = ['places', 'drawing'];

export const App = () => {
  useLang();

  return (
    <ThemeProvider>
      <QueryProvider>
        <ErrorBoundary>
          <APIProvider apiKey={GOOGLE_API_KEY} libraries={libraries}>
            <HistoryRouterProvider history={history}>
              <Suspense fallback={<Loading isFull />}>
                <Router />
              </Suspense>
            </HistoryRouterProvider>
          </APIProvider>
        </ErrorBoundary>
      </QueryProvider>
    </ThemeProvider>
  );
};
